<template>
  <!-- Game Type3 -->
  <div
    :id="'game-' + data.block"
    class="game-type3"
  >
    <v-container class="game__vendor">
      <v-row class="p-sticky top-0 z-index-1 bg">
        <v-col
          cols="12"
          class="py-0 px-1 mb-2 pl-4"
        >
          <!-- TAB -->
          <v-tabs
            v-model="tab"
            hide-slider
            class="game__tab"
            height="32"
            background-color="bg"
          >
            <v-tab
              v-for="(game,index) in navBars"
              :key="game._id"
              class="px-0 mx-2px"
            >
              <v-btn
                height="32"
                depressed
                class="px-2"
                :color="index === tab ? 'primary' : 'rgba(0,0,0,.0)'"
              >
                <Icon
                  :data="icons[game.class]"
                  width="20"
                  height="20"

                  :class="[ index === tab ? 'card1--text' : 'subtitle--text']"
                />
                <span
                  class="caption"
                  :class="[ index === tab ? 'card1--text' : 'subtitle--text']"
                >{{ game.game_type }}</span>
              </v-btn>
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <!-- content -->
      <v-row class="overlay-auto game-content px-14px">
        <v-col
          v-for="(item,index) in activeNavbarContent"
          :key="item._id"
          :cols="activeVendorType.isOdd && activeVendorType.stretchVendorIndex === index ? 12 : 6"
          class="px-1 py-1"
        >
          <div
            class="game-content rounded h-125 cursor-pointer"
            :style="gradientBg"
          >
            <v-img
              height="125"
              class="rounded"
              position="center right"
              :src="activeVendorType.isOdd && activeVendorType.stretchVendorIndex === index && item.image_h5_2 ? baseImgUrl + item.image_h5_2 : baseImgUrl + item.image_h5"
              @click="lauchGame(item.game_code, item.hyperlink)"
            >
              <div class="game-content__title pl-3 subtitle-2 card1--text w-100 backdrop-blur">
                <span>{{ item.title }}</span>
              </div>
            </v-img>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import esport from '@/assets/icons/block/game/type3/esport.svg'
import fish from '@/assets/icons/block/game/type3/fish.svg'
import live from '@/assets/icons/block/game/type3/live.svg'
import lottery from '@/assets/icons/block/game/type3/lottery.svg'
import slot from '@/assets/icons/block/game/type3/slot.svg'
import sport from '@/assets/icons/block/game/type3/sport.svg'
import card from '@/assets/icons/block/game/type3/card.svg'
import sabong from '@/assets/icons/block/game/type3/sabong.svg'

import { mapActions, mapGetters, mapState } from 'vuex'
import { goSlotPage } from '../../../../utils/slot'
import hexToRgba from 'hex-to-rgba'
import { resStatus } from '../../../../utils/resUtils'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    tab: 0,

    icons: {
      esport,
      fish,
      live,
      lottery,
      slot,
      sport,
      card,
      sabong,
    },
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'theme', 'gradientBg']),
    ...mapState({
      isLogin: state => state.user.isLogin,
      isLiffMode: state => state.line.liff.status,
    }),

    navBars() {
      return this.data.data.navbar
    },

    // 目前點擊的類別內 vendor
    activeNavbarContent() {
      return this.data.data.navbar[this.tab].items
    },

    activeVendorType() {
      const vendorLength = this.activeNavbarContent.length
      const isOdd = vendorLength % 2 !== 0

      return {
        isOdd,
        stretchVendorIndex: vendorLength === 1 ? 0 : vendorLength - 1,
      }
    },

    gameType() {
      return this.data.data.navbar[this.tab].class
    },

    primaryColor() {
      return this.$vuetify.theme.themes[this.theme]?.primary || ''
    },
  },

  methods: {
    ...mapActions([
      'get_game_url',
      'show_alert',
      'set_progress_status',
      'get_game_url_by_device',
    ]),

    /**
     * 啟動遊戲 OR slot 列表頁
     * @date 2021-07-02
     * @param {string} gameCode 遊戲代碼
     * @param {string} hyperlink 遊戲代碼
     */
    lauchGame(gameCode, hyperlink) {
      // 如果尚未登入
      if (!this.isLogin) {
        return this.show_alert({
          icon: 'fail',
          text: this.$t('flashMessage.loginFirst'),
        })
      }

      // 如果為 slot fish 就到 slot 頁面
      const goSlotPageItems = ['slot', 'fish']
      if (goSlotPageItems.includes(this.gameType)) return this.goSlotPage(hyperlink)

      console.log(`開啟遊戲 liff mode:${this.isLiffMode}`)

      const ua = navigator.userAgent.toLowerCase()
      console.log(ua)
      console.log('LINE browser: ' + ua.indexOf('line'))

      // 不為 liff 模式下
      if (!this.isLiffMode || ua.indexOf('line') < 0) {
        // 如果為 live || lottery 類型，則另開視窗 游戲
        if (this.gameType === 'live' || this.gameType === 'lottery') return this.goBlankPage(gameCode)
      }

      // 進入嵌入遊戲頁面
      this.$router.push({
        name: 'launchGame',
        query: {
          t: gameCode,
          redirectUrl: this.$route.path,
        },
      })
    },

    /**
     * 進入遊戲列表頁面
     * @date 2021-07-02
     * @param {string} hyperlink 遊戲代碼
     */
    goSlotPage(hyperlink) {
      goSlotPage(hyperlink)
    },

    /**
     * 另開遊戲視窗 (live)
     * @date 2021-08-04
     * @param {string} gameCode 遊戲代碼
     */
    async goBlankPage(gameCode) {
      this.set_progress_status(true)
      this.getGameUrl(gameCode)
    },

    /**
     * 取得遊戲 url (成功)
     * @date 2021-07-02
     * @param {string} gameUrl 遊戲連結 url
     */
    getGameUrlSuccess(gameUrl) {
      this.show_alert({ icon: 'done' })
      this.gameUrl = gameUrl
      this.set_progress_status(false)
      window.open(gameUrl, '_blank')
    },

    /**
     * 取得遊戲 url (失敗)
     * @date 2021-07-02
     */
    getGameUrlFail() {
      this.set_progress_status(false)
      this.show_alert({ icon: 'fail' })
      this.$router.replace({ path: '/' })
    },

    /**
     * 取得遊戲 url
     * @date 2021-07-02
     * @param {object} gameCode 遊戲代碼
     */
    async getGameUrl(gameCode) {
      const res = await this.get_game_url_by_device({ gamecode: gameCode })
      resStatus(res, this.getGameUrlSuccess, this.getGameUrlFail)
    },
  },
}
</script>

<style lang="scss" scoped>
.px-14px {
	padding: 0 14px;
}

.h-125 {
	height: 125px;
}

.game-content {
	position: relative;

	&__title {
		position: absolute;
		bottom: 0;
		height: 28px;
		background: rgba(0, 0, 0, 0.3);
		text-shadow: 0 0 30px #000;
		line-height: 28px;
	}

	&__bg {
		position: absolute;
		bottom: 0;
	}
}

.backdrop-blur {
	backdrop-filter: blur(2px);
}

.mx-2px {
	margin-right: 2px;
	margin-left: 2px;
}

.v-tab--active:hover::before,
.theme--dark.v-tabs .v-tab--active::before {
	opacity: 0;
}

</style>
